<template>
    <v-container>
        <v-card class="mb-10">
            <v-card-title class="card-title">Rules Form</v-card-title>
        </v-card>
        <div v-for="i in [1,2,3]" :key="i">
            <v-row align="center">
                <v-col cols="8">
                    <v-text-field
                        v-if="i === 1"
                        v-model="noOfMatches1.noOfMatches1"
                        :label="`#MatchesRule${i}`"
                        type="number"
                    />
                    <v-text-field
                        v-else-if="i === 2"
                        v-model="noOfMatches2.noOfMatches2"
                        :label="`#MatchesRule${i}`"
                        type="number"
                    />
                    <v-text-field
                        v-else
                        v-model="noOfMatches3.noOfMatches3"
                        :label="`#MatchesRule${i}`"
                        type="number"
                    />
                </v-col>
                <v-col cols="4" class="submit-button-wrapper">
                    <v-btn :loading="loading" class="mx-2 white--text" large color="black" @click="editRegulation(i)">
                        Submit
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import configService from '@/services/config'
    import Alert from '@/components/Alert'
    export default {
        name: 'RulesForm',
        components: { Alert },
        data: () => {
            return {
                showAlert: false,
                message: null,
                type: null,
                readOnly: false,
                clearable: true,
                loading: false,
                noOfMatches1: 0,
                noOfMatches2: 0,
                noOfMatches3: 0,
                rules: [
                    v => !!v || 'Field is required'
                ]
            }
        },
        created() {
            configService.getConfigField('noOfMatches1').then(resp => {
                this.noOfMatches1 = resp.data
            })
            configService.getConfigField('noOfMatches2').then(resp => {
                this.noOfMatches2 = resp.data
            })
            configService.getConfigField('noOfMatches3').then(resp => {
                this.noOfMatches3 = resp.data
            })
        },
        methods: {
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            editRegulation(id) {
                const field = id === 1 ? 'noOfMatches1' : (id === 2 ? 'noOfMatches2' : 'noOfMatches3')
                const value = id === 1 ? this.noOfMatches1 : (id === 2 ? this.noOfMatches2 : this.noOfMatches3)
                configService.updateConfigField(field, value).then(resp => {
                    this.loading = false
                    this.setAlert(`#MatchesRule${id} updated successfully`, 'success')
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            }
        }
    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

</style>
